import React, { useState, useEffect } from "react";
import axios from "axios";
import Carrousel from "./Carrousel";
import { useParams } from "react-router-dom";

const getRandomSample = (array) => {
  // Shuffle the array
  const shuffledArray = array.sort(() => Math.random() - 0.5);
  // Get up to 10 items from the shuffled array
  const sample = shuffledArray.slice(0, Math.min(15, shuffledArray.length));
  return sample;
};

const BookNavigator = ({
  booksURLs = [],
  keyword = null,
  title,
  text,
  classModifier,
}) => {
  const { id } = useParams();
  const [books, setBooks] = useState([]);

  useEffect(() => {
    if (keyword) {
      axios
        .get(`${API_URL}/book-group/${keyword}`)
        .then((res) => {
          const booksList = getRandomSample(res.data);
          setBooks(booksList);
        })
        .catch((error) => {
          console.log("Error:", error.response);
        });
    } else if (booksURLs) {
      Promise.all(
        booksURLs.map((book) =>
          axios
            .get(`${API_URL}/bookURL/${book}`)
            .then((res) => res.data)
            .catch((error) => {
              console.log("Error:", error.response);
              return null; // or some default value
            })
        )
      )
        .then((responses) => {
          const filteredResponses = responses.filter(Boolean); // Filter out null responses
          setBooks(filteredResponses);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    }
  }, [id]); //Ensure it fetches new books when we change the page, even if the new page has the same keyword prop

  return (
    books.length > 0 && (
      <section className={`bookNavigation bookNavigation--${classModifier}`}>
        {title && (
          <h2 className="content-container bookNavigation__title">{title}</h2>
        )}
        {text && (
          <div className="content-container">
            <p>{text}</p>
          </div>
        )}
        <Carrousel books={books} />
      </section>
    )
  );
};

export default BookNavigator;
