import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <p>
      © Copyright 2020 - <Link to="/aviso-legal">Aviso Legal</Link> -{" "}
      <Link to="/politica-privacidad">Política de Privacidad</Link> -{" "}
      <Link to="/contacto">Contacto</Link>
    </p>
  </footer>
);

export default Footer;
