import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import prettyMilliseconds from "pretty-ms";
import BookNavigator from "./BookNavigator";
import NotFoundPage from "../components/NotFoundPage";
import Loading from "../components/Loading";

const BookPage = () => {
  const { id } = useParams();
  const [speed, setSpeed] = useState(250);
  const [book, setBook] = useState({});
  const [loading, setLoading] = useState(true);
  const [averageDefault, setAverageDefault] = useState("");
  const [average, setAverage] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchBookData(id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBookData(id);
  }, [id]);

  const handleSpeed = (e) => {
    e.preventDefault();
    const a = prettyMilliseconds((book.pages * 250) / (speed / 60000));
    setAverage(a);
  };

  const onSpeedChange = (e) => {
    setSpeed(e.target.value);
  };

  const fetchBookData = (url) => {
    setError(false);
    setLoading(true);
    axios
      .get(`${API_URL}/bookURL/${url}`)
      .then((res) => {
        setBook(res.data);
        const avDef = prettyMilliseconds(
          (res.data.pages * 250) / (speed / 60000)
        );
        setAverageDefault(avDef);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  return error ? (
    <NotFoundPage type="book" />
  ) : (
    <div>
      <Helmet>
        <title>{`¿Cuánto se tarda en leer ${book.title} ? | Tiempo lectura`}</title>
        <meta
          name="description"
          content={`Descubre cuánto tiempo necesitas para leer ${book.title} según tu ritmo de lectura.`}
        />
        <meta
          property="og:description"
          content={`Descubre cuánto tiempo necesitas para leer ${book.title} según tu ritmo de lectura.`}
        />
        <meta
          property="og:title"
          content={`¿Cuánto se tarda en leer este libro?`}
        />
        <meta property="og:image" content={book.imageBig} />
      </Helmet>
      <div className="content-container">
        {loading == true && (
          <div className="bookPage">
            <div className="bookPage__image-container"></div>
            <div className="bookPage__loading">
              <Loading />
            </div>
          </div>
        )}
        {loading == false && (
          <div className="bookPage">
            <a
              href={book.link}
              className="bookPage__image-container"
              target="_blank"
            >
              <img
                loading="lazy"
                src={book.imageBig}
                className="bookPage__image"
                alt="Portada del libro"
              />
            </a>
            <div className="bookPage__info">
              <h1 className="bookPage__title">
                Cuánto tiempo se tarda en leer <br />
                <span>{book.title}</span>
              </h1>
              <p className="bookPage__author"> {book.author}</p>
              <div className="bookPage__facts-and-image">
                <a
                  href={book.link}
                  className="bookPage__small-image-container"
                  target="_blank"
                >
                  <img
                    width="49px"
                    height="75px"
                    loading="lazy"
                    src={book.imageSmall}
                    className="bookPage__image"
                    alt="Portada del libro"
                  />
                </a>
                <div className="bookPage__facts">
                  <div className="bookPage__fact bookPage__fact--pages">
                    <p>{book.pages} páginas</p>
                  </div>
                  <div className="bookPage__fact bookPage__fact--time">
                    <p>{averageDefault}</p>
                  </div>
                  <div className="bookPage__fact bookPage__fact--price">
                    <p>{book.price}€</p>
                  </div>
                </div>
              </div>
              <p>
                El lector promedio tardará{" "}
                <span className="bookPage__average">{averageDefault}</span> en
                leer este libro. La velocidad del lector promedio es de{" "}
                <b>250</b> palabras por minuto.
              </p>
              <a href={book.link} target="_blank" className="bookPage__amazon">
                <img
                  width="100px"
                  height="35px"
                  src="/img/amazon-logo.png"
                  alt="Logo de Amazon"
                />
                <p>
                  Cómpralo ahora por solo <span>{book.price}€</span>
                </p>
              </a>
              <h2>¿Cuál es tu velocidad de lectura?</h2>
              <p>
                Descubre tu ritmo de lectura habitual utilizando nuestra
                calculadora de velocidad:
              </p>
              <a className="bookPage__test" href="/test">
                Ir a la calculadora de velocidad
              </a>
              <p>
                Escribe aquí las <b>palabras por minuto</b> que has obtenido
                como resultado en el test:
              </p>

              <form className="bookPage__form" onSubmit={handleSpeed}>
                <input
                  className="bookPage__input"
                  type="number"
                  min="50"
                  max="1000"
                  placeholder="palabras por minuto"
                  onChange={onSpeedChange}
                  required
                />
                <button type="submit">Calcular</button>
              </form>
              {average && (
                <p>
                  Tardarás <span className="bookPage__average">{average}</span>{" "}
                  en leer este libro
                </p>
              )}

              {book.description && (
                <div className="bookPage__sinopsis">
                  <h3>Sinopsis:</h3>
                  <p>{book.description}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {book.author && <BookNavigator keyword={book.group} />}
      <div className="content-container">
        <p className="bookPage__affiliates">
          Esta página participa en el Programa de Afiliados de Amazon EU, un
          programa de publicidad para afiliados diseñado para ofrecer a sitios
          web un modo de obtener comisiones por publicidad, publicitando e
          incluyendo enlaces a Amazon.es.
        </p>
      </div>
    </div>
  );
};

export default BookPage;
