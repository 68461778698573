import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchResults from "./SearchResults";
import { useNavigate } from "react-router-dom";

const SearchArea = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!keyword) {
      setBooks([]);
    } else {
      axios
        .get(`${API_URL}/book/` + keyword)
        .then((res) => {
          setBooks(res.data);
        })
        .catch((error) => {
          console.log("Error:", error.response);
        });
    }
  }, [keyword]);

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    setNotFound(false)
    axios
      .post(`${API_URL}/search/` + keyword)
      .then((res) => {
        setLoading(false);
        setNotFound(false)
        const url = res.data.url;
        navigate(`/${url}`);
      })
      .catch((error) => {
        console.log("Error:", error);
        setLoading(false);
        setNotFound(true)
      });
  };

  const onSearchChange = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  return (
    <div>
      <form className="form_searchArea" onSubmit={handleSearch}>
        <input
          className="form_searchArea__input"
          type="text"
          placeholder="Busca según título y/o autor"
          value={keyword}
          onChange={onSearchChange}
          aria-label="Buscar libro"
          id="search_book"
          required
        />
        <button className="form_searchArea__btn" type="submit">
          Buscar
        </button>
        <SearchResults books={books} />
      {loading && <div className="loadingProgressG"></div>}
      { notFound && <p className="form_searchArea__notFound">No hemos encontrado ningun libro para esta búsqueda. Inténtalo de nuevo con otras palabras</p>}
      </form>
    </div>
  );
};

export default SearchArea;
