import React from "react";
import { useNavigate } from "react-router-dom";

const SearchResults = ({books}) => (
  <div className="searchResults">
    {books.slice(0, 4).map((book) => {
      //Agafo només els quatre primers resultats
      return <ResultItem key={book._id} book={book} />;
    })}
  </div>
);

const ResultItem = ({ book }) => {
  const navigate = useNavigate();

  const handleSelect = () => {
    const url = book.url;
    navigate(`/${url}`);
  };

  return (
    <div onClick={handleSelect} className="searchResults__item">
      <img src={book.imageSmall} className="searchResults__img" />
      <div>
        <p className="searchResults__title">{book.title}</p>
        <p className="searchResults__author">{book.author}</p>
      </div>
    </div>
  );
};

export default SearchResults;
