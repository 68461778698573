import React from "react";
import { Helmet } from "react-helmet";
import SearchArea from "./SearchArea";
import BookNavigator from "./BookNavigator";

const popular = [
  "origen", //novela-negra
  "sapiens-de-animales-a-dioses-una-breve-historia-de-la-humanidad", //historia
  "harry-potter-y-la-piedra-filosofal", //juvenil
  "divina-comedia", //poesia
  "el-arbol-de-la-ciencia", // narrativa-en-bolsillo
  "la-celestina", //poesia
  "el-lazarillo-de-tormes", //narrativa-en-bolsillo
  "luces-de-bohemia", //poesia
  "dracula", //narrativa-fantastica
  "dune", // novela-de-ciencia-ficcion
  "trilogia-de-sprawl-neuromante", // novela-de-ciencia-ficcion
  "trilogia-de-la-fundacion", // novela-de-ciencia-ficcion
  "la-ladrona-de-libros", //juvenil
  "padre-rico-padre-pobre", //economia-empresa

];

const cortas = [
  "rebelion-en-la-granja", //narrativa-en-bolsillo
  "romeo-y-julieta", //romantica-y-erotica
  "el-viejo-y-el-mar", //narrativa-en-bolsillo
  "el-extrano-caso-de-dr-jekyll-y-mr-hyde", //novela-contemporanea
  "el-guardian-entre-el-centeno", // narrativa-en-bolsillo
  "siddhartha", //narrativa-en-bolsillo
  "un-mundo-feliz", //narrativa-en-bolsillo
  "el-extranjero", //undefined
  "el-principito", //infantil
  "desayuno-en-tiffanys", //narrativa-en-bolsillo
  "cronica-de-una-muerte-anunciada", //narrativa-en-bolsillo
  "verdad-o-reto", //novela negra
  "la-isla-del-tesoro", //juvenil
  "el-arte-de-la-guerra", //ciencias-humanas
  "lo-que-los-libros-de-historia-del-arte-no-quieren-que-sepas", //arte
  "el-juego-mental-del-poker", //ocio-deporte
];

const largas = [
  "en-busca-del-tiempo-perdido", //novela-contemporanea
  "viaje-al-oeste-las-aventuras-del-rey-mono", //novela-historica
  "los-miserables", //narrativa-en-bolsillo
  "la-novela-de-genji", //novela-historica
  "guerra-y-paz",//narrativa-en-bolsillo
  "apocalipsis", //narrativa-en-bolsillo
  "it-eso", //narrativa-fantastica
  "el-senor-de-los-anillos", //narrativa-fantastica
  "1q84-libros-1-y-2", //novela-contemporanea
  "trilogia-reina-roja", //novela-negra
  "un-cor-furtiu-vida-de-josep-pla", // filologia
  "manual-de-diagnostico-y-terapeutica-en-pediatria" //ciencias
];

const InitialPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          ¿Cuánto tiempo se tarda en leer este libro? | Tiempo lectura
        </title>
        <meta
          name="description"
          content="Descubre cuánto tiempo necesitas para leer cualquier libro según tu ritmo de lectura específico"
        />
        <meta
          property="og:description"
          content="Descubre cuánto tiempo necesitas para leer cualquier libro según tu ritmo de lectura específico"
        />
        <meta
          property="og:title"
          content="¿Cuánto se tarda en leer este libro?"
        />
      </Helmet>
      <div className="initialPage">
        <div className="content-container  initialPage__container">
          <h1 className="initialPage__title">
            {"¿Cuánto voy a tardar en \n leer este libro?"}
          </h1>
          <SearchArea />
        </div>
        <div className="initialPage__img"></div>
      </div>
      <BookNavigator
        booksURLs={popular}
        title="Los libros más buscados"
        text="Como podrás imaginar, no eres el único que ha pasado por aquí para averiguar cuánto tiempo se necesita para leer un libro en particular. Esta lista incluye algunos de los libros más buscados por nuestros usuarios.
       ¿Te los has leído ya?"
      />
      <BookNavigator
        booksURLs={cortas}
        title="Clásicos para leer en una tarde"
        text="No todos los clásicos de la literatura universal son tochos de más de 600 páginas como el Quijote, la Divina comedia o cualquier novela rusa.
       Si tienes una tarde libre, desconecta el móvil, prepárate un té y siéntate en tu sillón favorito para disfrutar de alguno de estos clásicos. "
        classModifier="2"
      />
      <BookNavigator
        booksURLs={largas}
        title="Las novelas más largas de la historia"
        text="Hay libros que no quieres que se acaben nunca. Con los libros de esta lista podrías estar literalmente un día entero leyendo y todavía no te los habrías terminado."
        classModifier="3"
      />
    </div>
  );
};

export default InitialPage;
