import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createRef } from "react";

const Carrousel = ({ books }) => {
  const [topLeft, setTopLeft] = useState(true);
  const [topRight, setTopRight] = useState(false);
  const carrousel = createRef();

  const scroll = (direction) => {
    carrousel.current.scrollBy({
      top: 0,
      left:
        direction === "right"
          ? window.innerWidth - 100
          : -window.innerWidth + 100,
      behavior: "smooth",
    });
  };

  const scrollState = (e) => {
    const element = e.target;
    setTopLeft(element.scrollLeft <= 0 ? true : false);
    setTopRight(
      element.scrollLeft + element.offsetWidth >= element.scrollWidth
        ? true
        : false
    );
  };

  return (
    <div className="carrousel-container">
      {books && (
        <div className="carrousel" ref={carrousel} onScroll={scrollState}>
          <button
            onClick={() => scroll("left")}
            className="carrousel__btn-left"
            aria-label="Deslizar a la izquierda"
            disabled={topLeft}
          >
            {"‹"}
          </button>
          {books.map((book, i) => {
            return (
              <Link to={`/${book.url}`} key={i} className="carrousel__item">
                <img loading="lazy" src={book.imageBig} alt={book.title} />
              </Link>
            );
          })}
          <button
            onClick={() => scroll("right")}
            className="carrousel__btn-right"
            aria-label="Deslizar a la derecha"
            disabled={topRight}
          >
            {"›"} 
          </button>
        </div>
      )}
    </div>
  );
};

export default Carrousel;
