import React, { useState, useEffect } from "react";

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);  
  const [average, setAverage] = useState(0)

  useEffect(() => {
    let interval;

    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  const calculateAverage = () => {
    const wordCount = 1523;
    const seconds =  parseInt(time / 1000) || 1;

    return  Math.round(wordCount * 60 /parseInt(seconds))
  } 

  return (
    <div className="stopwatch">
      <div className="stopwatch__main">
        <div className="stopwatch__timer"> 
          <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
          <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}:</span>
          <span>{("0" + ((time / 10) % 100)).slice(-2)}</span>
        </div>
        <div className="stopwatch__buttons">
          {!running && <button className="stopwatch__iniciar" onClick={() => setRunning(true)}>Iniciar</button>}
          {running && <button className="stopwatch__parar" onClick={() => setRunning(false)}>Parar</button>}
          <button className="stopwatch__reiniciar" onClick={() => {setTime(0); setAverage(0)}}>Reiniciar</button>
          {(!running && time != 0 )&& <button className="stopwatch__calcular" onClick={() => setAverage(calculateAverage)}>Calcular velocidad</button>}
        </div>
      </div>
      <div className="stopwatch__average">
        {average != 0 && <p> Tu velocidad de lectura es de <span>{average}</span> palabras por minuto. </p>}
      </div>
    </div>
  );

}

export default Stopwatch;