import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import InitialPage from "../components/InitialPage";
import BookPage from "../components/BookPage";
import Test from "../components/Test";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import NotFoundPage from "../components/NotFoundPage";
import Loading from "../components/Loading";

// No need to include this pages in the main bundle
const AvisoLegal = lazy(() => import("../components/AvisoLegal"));
const PoliticaPrivacidad = lazy(() =>
  import("../components/PoliticaPrivacidad")
);
const Contacto = lazy(() => import("../components/Contacto"));

const AppRouter = () => (
  <BrowserRouter>
    <div className="layout">
      <Suspense fallback={<Loading />}>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<InitialPage />} exact={true} />
          <Route path="/404" element={NotFoundPage} />
          <Route path="/aviso-legal" element={<AvisoLegal />} />
          <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/test" element={<Test />} />
          <Route path="/:id/*" element={<BookPage />} />
          <Route element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  </BrowserRouter>
);

export default AppRouter;
