import React from "react";
import { Helmet } from "react-helmet";
import Stopwatch from "./Stopwatch";

const Test = () => (
  <div className="content-container content-container--box">
    <Helmet>
      <title>Calcula tu velocidad de lectura</title>
      <meta
        name="description"
        content={`Descubre cuál es tu velocidad de lectura`}
      />
    </Helmet>
    <h1>Calcula tu velocidad de lectura</h1>
    <p>
      Esta página te ayudará a conocer tu velocidad de lectura. Simplemente
      inicia el cronómetro y comienza a leer el siguiente texto. Una vez que
      termines, detén el cronómetro y la calculadora estimará tu velocidad de
      lectura en palabras por minuto.
    </p>
    <p>
      Puedes utilizar este dato en cualquiera de nuestras páginas para calcular
      cuánto tiempo necesitarás para leer el libro que desees.
    </p>

    <div className="test__article-container">
      <Stopwatch />
      <article className="test__article">
        <p>
          Todavía recuerdo aquel amanecer en que mi padre me llevó por primera
          vez a visitar el Cementerio de los Libros Olvidados. Desgranaban los
          primeros días del verano de 1945 y caminábamos por las calles de una
          Barcelona atrapada bajo cielos de ceniza y un sol de vapor que se
          derramaba sobre la Rambla de Santa Mónica en una guirnalda de cobre
          líquido.
        </p>
        <p>
          —Daniel, lo que vas a ver hoy no se lo puedes contar a nadie —advirtió
          mi padre—. Ni a tu amigo Tomás. A nadie.
        </p>
        <p>—¿Ni siquiera a mamá?—inquirí yo, a media voz.</p>
        <p>
          Mi padre suspiró, amparado en aquella sonrisa triste que le perseguía
          como una sombra por la vida.
        </p>
        <p>
          —Claro que sí —respondió cabizbajo—. Con ella no tenemos secretos. A
          ella puedes contárselo todo.
        </p>
        <p>
          Poco después de la guerra civil, un brote de cólera se había llevado a
          mi madre. La enterramos en Montjuïc el día de mi cuarto cumpleaños.
          Sólo recuerdo que llovió todo el día y toda la noche, y que cuando le
          pregunté a mi padre si el cielo lloraba le faltó la voz para
          responderme. Seis años después, la ausencia de mi madre era para mí
          todavía un espejismo, un silencio a gritos que aún no había aprendido
          a acallar con palabras. Mi padre y yo vivíamos en un pequeño piso de
          la calle Santa Ana, junto a la plaza de la iglesia. El piso estaba
          situado justo encima de la librería especializada en ediciones de
          coleccionista y libros usados heredada de mi abuelo, un bazar
          encantado que mi padre confiaba en que algún día pasaría a mis manos.
          Me crié entre libros, haciendo amigos invisibles en páginas que se
          deshacían en polvo y cuyo olor aún conservo en las manos. De niño
          aprendí a conciliar el sueño mientras le explicaba a mi madre en la
          penumbra de mi habitación las incidencias de la jornada, mis andanzas
          en el colegio, lo que había aprendido aquel día... No podía oír su voz
          o sentir su tacto, pero su luz y su calor ardían en cada rincón de
          aquella casa y yo, con la fe de los que todavía pueden contar sus años
          con los dedos de las manos, creía que si cerraba los ojos y le
          hablaba, ella podría oírme desde donde estuviese. A veces, mi padre me
          escuchaba desde el comedor y lloraba a escondidas.
        </p>
        <p>
          Recuerdo que aquel alba de junio me desperté gritando. El corazón me
          batía en el pecho como si el alma quisiera abrirse camino y echar a
          correr escaleras abajo. Mi padre acudió azorado a mi habitación y me
          sostuvo en sus brazos, intentando calmarme.
        </p>
        <p>
          —No puedo acordarme de su cara. No puedo acordarme de la cara de mamá
          —murmuré sin aliento.
        </p>
        <p>Mi padre me abrazó con fuerza.</p>
        <p>—No te preocupes, Daniel. Yo me acordaré por los dos.</p>
        <p>
          Nos miramos en la penumbra, buscando palabras que no existían. Aquélla
          fue la primera vez en que me di cuenta de que mi padre envejecía y de
          que sus ojos, ojos de niebla y de pérdida, siempre miraban atrás. Se
          incorporó y descorrió las cortinas para dejar entrar la tibia luz del
          alba.
        </p>
        <p>—Anda, Daniel, vístete. Quiero enseñarte algo —dijo.</p>
        <p>
          —¿Ahora? ¿A las cinco de la mañana? —Hay cosas que sólo pueden verse
          entre tinieblas —insinuó mi padre blandiendo una sonrisa enigmática
          que probablemente había tomado prestada de algún tomo de Alejandro
          Dumas.
        </p>
        <p>
          Las calles aún languidecían entre neblinas y serenos cuando salimos al
          portal. Las farolas de las Ramblas dibujaban una avenida de vapor,
          parpadeando al tiempo que la ciudad se desperezaba y se desprendía de
          su disfraz de acuarela. Al llegar a la calle Arco del Teatro nos
          aventuramos camino del Raval bajo la arcada que prometía una bóveda de
          bruma azul. Seguí a mi padre a través de aquel camino angosto, más
          cicatriz que calle, hasta que el reluz de la Rambla se perdió a
          nuestras espaldas. La claridad del amanecer se filtraba desde balcones
          y cornisas en soplos de luz sesgada que no llegaban a rozar el suelo.
          Finalmente, mi padre se detuvo frente a un portón de madera labrada
          ennegrecido por el tiempo y la humedad. Frente a nosotros se alzaba lo
          que me pareció el cadáver abandonado de un palacio, o un museo de ecos
          y sombras.
        </p>
        <p>
          —Daniel, lo que vas a ver hoy no se lo puedes contar a nadie. Ni a tu
          amigo Tomás. A nadie.
        </p>
        <p>
          Un hombrecillo con rasgos de ave rapaz y cabellera plateada nos abrió
          la puerta. Su mirada aguileña se posó en mí, impenetrable.
        </p>
        <p>
          —Buenos días, Isaac. Éste es mi hijo Daniel —anunció mi padre—. Pronto
          cumplirá once años, y algún día él se hará cargo de la tienda. Ya
          tiene edad de conocer este lugar.
        </p>
        <p>
          El tal Isaac nos invitó a pasar con un leve asentimiento. Una penumbra
          azulada lo cubría todo, insinuando apenas trazos de una escalinata de
          mármol y una galería de frescos poblados con figuras de ángeles y
          criaturas fabulosas. Seguimos al guardián a través de aquel corredor
          palaciego y llegamos a una gran sala circular donde una auténtica
          basílica de tinieblas yacía bajo una cúpula acuchillada por haces de
          luz que pendían desde lo alto. Un laberinto de corredores y
          estanterías repletas de libros ascendía desde la base hasta la
          cúspide, dibujando una colmena tramada de túneles, escalinatas,
          plataformas y puentes que dejaban adivinar una gigantesca biblioteca
          de geometría imposible. Miré a mi padre, boquiabierto. Él me sonrió,
          guiñándome el ojo.
        </p>
        <p>—Daniel, bien venido al Cementerio de los Libros Olvidados.</p>
        <p>
          Salpicando los pasillos y plataformas de la biblioteca se perfilaban
          una docena de figuras. Algunas de ellas se volvieron a saludar desde
          lejos, y reconocí los rostros de diversos colegas de mi padre en el
          gremio de libreros de viejo. A mis ojos de diez años, aquellos
          individuos aparecían como una cofradía secreta de alquimistas
          conspirando a espaldas del mundo. Mi padre se arrodilló junto a mí y,
          sosteniéndome la mirada, me habló con esa voz leve de las promesas y
          las confidencias.
        </p>
        <p>
          —Este lugar es un misterio, Daniel, un santuario. Cada libro, cada
          tomo que ves, tiene alma. El alma de quien lo escribió, y el alma de
          quienes lo leyeron y vivieron y soñaron con él. Cada vez que un libro
          cambia de manos, cada vez que alguien desliza la mirada por sus
          páginas, su espíritu crece y se hace fuerte. Hace ya muchos años,
          cuando mi padre me trajo por primera vez aquí, este lugar ya era
          viejo. Quizá tan viejo como la misma ciudad. Nadie sabe a ciencia
          cierta desde cuándo existe, o quiénes lo crearon. Te diré lo que mi
          padre me dijo a mí. Cuando una biblioteca desaparece, cuando una
          librería cierra sus puertas, cuando un libro se pierde en el olvido,
          los que conocemos este lugar, los guardianes, nos aseguramos de que
          llegue aquí. En este lugar, los libros que ya nadie recuerda, los
          libros que se han perdido en el tiempo, viven para siempre, esperando
          llegar algún día a las manos de un nuevo lector, de un nuevo espíritu.
          En la tienda nosotros los vendemos y los compramos, pero en realidad
          los libros no tienen dueño. Cada libro que ves aquí ha sido el mejor
          amigo de alguien. Ahora sólo nos tienen a nosotros, Daniel. ¿Crees que
          vas a poder guardar este secreto?
        </p>
        <p>
          Mi mirada se perdió en la inmensidad de aquel lugar, en su luz
          encantada. Asentí y mi padre sonrió.
        </p>
        <p>—¿Y sabes lo mejor? —preguntó.</p>
        <p>Negué en silencio.</p>
        <p>
          —La costumbre es que la primera vez que alguien visita este lugar
          tiene que escoger un libro, el que prefiera, y adoptarlo, asegurándose
          de que nunca desaparezca, de que siempre permanezca vivo. Es una
          promesa muy importante. De por vida —explicó mi padre—. Hoy es tu
          turno.
        </p>
        <p>
          Por espacio de casi media hora deambulé entre los entresijos de aquel
          laberinto que olía a papel viejo, a polvo y a magia. Dejé que mi mano
          rozase las avenidas de lomos expuestos, tentando mi elección. Atisbé,
          entre los títulos desdibujados por el tiempo, palabras en lenguas que
          reconocía y decenas de otras que era incapaz de catalogar. Recorrí
          pasillos y galerías en espiral pobladas por cientos, miles de tomos
          que parecían saber más acerca de mí que yo de ellos. Al poco, me
          asaltó la idea de que tras la cubierta de cada uno de aquellos libros
          se abría un universo infinito por explorar y de que, más allá de
          aquellos muros, el mundo dejaba pasar la vida en tardes de fútbol y
          seriales de radio, satisfecho con ver hasta allí donde alcanza su
          ombligo y poco más. Quizá fue aquel pensamiento, quizá el azar o su
          pariente de gala, el destino, pero en aquel mismo instante supe que ya
          había elegido el libro que iba a adoptar. O quizá debiera decir el
          libro que me iba a adoptar a mí. Se asomaba tímidamente en el extremo
          de una estantería, encuadernado en piel de color vino y susurrando su
          título en letras doradas que ardían a la luz que destilaba la cúpula
          desde lo alto. Me acerqué hasta él y acaricié las palabras con la yema
          de los dedos, leyendo en silencio.
        </p>
      </article>
    </div>
    <p>
      El texto que acabas de leer son las primeras líneas de la novela "La
      Sombra del viento" de Carlos Ruiz Zafón. Puedes descargar los primeros
      capítulos de la novela en la{" "}
      <a
        target="_blank"
        href="https://www.carlosruizzafon.com/la-sombra-del-viento/primer-capitulo.php"
      >
        página web del autor.
      </a>
    </p>
  </div>
);

export default Test;
