import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => (
  <div className="header">
    <div className="content-container">
      <nav className="header__nav">
        <NavLink to="/">
          <img width="40px" height="40px" src="/img/logo.svg" className="header__logo" alt="logo" />
        </NavLink>
        <div className="">
          <ul className="header__navlink">
            <li>
              <NavLink
                to="/test"
                className={({ isActive }) =>
                  isActive ? "header__link--active" : ""
                }
              >
                Calculadora de velocidad
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
);

export default Header;
