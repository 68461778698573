import React from "react";
import SearchArea from "./SearchArea";
import BookNavigator from "./BookNavigator";

const NotFoundPage = () => (
  <div className="notFound">
    <div className="content-container">
      <h1>No hemos encontrado esta página</h1>
      <p>Si estabas buscando un libro inténtalo de nuevo con otras palabras</p>
      <SearchArea />
    </div>
    <BookNavigator keyword="novela-contemporanea" />
  </div>
);

export default NotFoundPage;
